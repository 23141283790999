import React from 'react';
import "./css/contact.css";

class Contact extends React.Component {
  render() {
    return (
    <div class="contact-container">
        <div class="company-info">
        <h1>Photovoltaic Power Systems LLC</h1>
        <p>DBA: PV Solar</p>
        </div>
        <div class="contact-info">
        <div class="contact-box">
            <h2>Contact Us</h2>
            <p>Address: 3960 W Point Loma Blvd, San Diego, CA 92110</p>
            <p>Email: pvpowersystems@hotmail.com</p>
            <p>Phone: 619-932-0537</p>
            <p>Website: <a href="https://pvsolar.energy">pvsolar.energy</a></p>
        </div>
        </div>
    </div>
    );
  }
}

export default Contact;
