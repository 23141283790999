import React from 'react';
import "./css/battery.css";
import location from "./media/location.PNG";
import solar from "./media/solar-farm.jpg";
import storage from "./media/storage.PNG";
import nem from "./media/cpuc.jpg";
import battery from "./media/battery.png";
import battery2 from "./media/battery-2.png";
import blackout from "./media/blackout.png";
import clean from "./media/clean.jpg";
import energy from "./media/energy.png";


class Battery extends React.Component {
  render() {
    return (
      <div>
        <div class="battery-container">
          <div class="block split">
              <div class="half ">
                <img src={battery} alt="Solar Farm" class="full-image"/>
              </div>
              <div class="half text">
                <div class="content">
                  <div class="large-text">Rechargeable solar battery for your home</div>
                  <div class="subtext">Reliable energy when you need it most.</div>
                </div>
              </div>
          </div>
          <div class="block split">
              <div class="half text">
                <div class="content">
                  <div class="middle-text">Power through outages with a home battery</div>
                  <div class="subtext">Harness the power of the sun even when it sets.</div>
                  <div class="small-text">Our advanced solar batteries seamlessly integrate with your panels, capturing and storing excess energy for use at night or during outages. With reliable technology, enjoy round-the-clock clean energy and greater electricity control, reducing utility bills and boosting energy independence. Power your home sustainably with PV Solar's battery for a brighter future day and night.</div>
                </div>
              </div>
              <div class="half">
                <img src={battery2} alt="Enphase Energy Storage" class="full-image"/>
              </div>
          </div>

          <div class="triple-column-container">
            <div class="column">
              <img src={blackout} alt="Image Description" />
              <div class="large">Emergency backup during grid outages</div>
              <div class="small">Emergency backup during grid outages is crucial because it ensures that your essential appliances and security systems continue to operate uninterrupted, providing peace of mind during unexpected power cuts. This backup capability is particularly important in areas prone to extreme weather or unreliable grid infrastructure, where outages can be frequent and prolonged. By having an emergency power source, homeowners can maintain a sense of normalcy and safety when the grid fails, protecting their family and property.</div>
            </div>
            <div class="column">
              <img src={energy} alt="Image Description" />
              <div class="large">Reduce energy purchased from grid</div>
              <div class="small">Reducing energy purchased from the grid is key for lowering electricity bills and increasing independence from utility companies. By generating your own power, typically through renewable sources like solar, you contribute to a greener environment by decreasing reliance on fossil-fueled grid energy. This self-sufficiency not only saves money but also ensures a more sustainable and environmentally friendly energy consumption model.</div>
            </div>
            <div class="column">
              <img src={clean} alt="Image Description" />
              <div class="large">Build a cleaner world</div>
              <div class="small">Building a cleaner world is vital for ensuring a sustainable and healthy environment for future generations. By adopting eco-friendly practices and renewable energy sources, we reduce pollution and combat climate change, preserving the planet's natural resources. This commitment to environmental stewardship not only enhances the quality of life for all living beings but also paves the way for a greener, more prosperous global community.</div>
            </div>
          </div>

          <div className="block centered-table">
            <div className="table-container">
              <table>
                {/* Assuming you want the first row to be headers */}
                <thead>
                  <tr>
                    <th>IQ Battery 5P</th>
                    <th>IQ Battery 10T</th>
                    <th>IQ Battery 3T</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div>5 kWh</div>
                      <div>Energy Capacity</div>
                    </td>
                    <td>
                      <div>10.08 kWh</div>
                      <div>Energy Capacity</div>
                    </td>
                    <td>
                      <div>3.5 kWh</div>
                      <div>Energy Capacity</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>7.68 kVA (3 seconds)</div>
                      <div>6.14 kVA (10 seconds)</div>
                      <div>Peak power output</div>
                    </td>
                    <td>
                      <div>5.7 kVA</div>
                      <div>(10 seconds)</div>
                      <div>Peak power output</div>
                    </td>
                    <td>
                      <div>1.92 kVA</div>
                      <div>(10 seconds)</div>
                      <div>Peak power output</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>3.84 kVA</div>
                      <div>Continuous power output</div>
                    </td>
                    <td>
                      <div>3.84 kVA</div>
                      <div>Continuous power output</div>
                    </td>
                    <td>
                      <div>1.28 kVA</div>
                      <div>Continuous power output</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>38.6 in x 21.7 in x 7.4 in</div>
                      <div>Dimensions</div>
                    </td>
                    <td>
                      <div>50.5 in x 30.5 in x 7.4 in</div>
                      <div>Dimensions</div>
                    </td>
                    <td>
                      <div>16.9 in x 30.5 in x 7.4 in</div>
                      <div>Dimensions</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>15-year limited warranty</div>
                    </td>
                    <td>
                      <div>10-year limited warranty</div>
                    </td>
                    <td>
                      <div>10-year limited warranty</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="mobile-battery-container">
          <div class="mobile-block battery">
              About
          </div>
          <div class="mobile-block">
            <img src={location} alt="map" class="full-image"/>
          </div>
          <div class="mobile-block text">
            <div class="content">
                Location
                <div class="subtext">We proudly serve the entire San Diego County, including Chula Vista, Oceanside, Escondido, Carlsbad, Jamul, Bonita, Del Mar, La Jolla, and more.</div>
            </div>
          </div>
          <div class="mobile-block">
            <img src={solar} alt="solar" class="full-image"/>
          </div>
          <div class="mobile-block text">
              <div class="content">
                  Solar Panels
                  <div class="subtext">Transform your home with our advanced, sustainable solar technology, reducing energy costs and enhancing home value through eco-friendly, long-term savings. Embrace renewable energy for efficient, self-sufficient power, leading the way in energy innovation and eco-conscious living.</div>
              </div>
          </div>
          <div class="mobile-block">
            <img src={storage} alt="storage" class="full-image"/>
          </div>
          <div class="mobile-block text">
            <div class="content">
                Batteries
                <div class="subtext">Enhance energy independence and savings with our advanced battery systems, designed for outage protection and grid support, complete with convenient remote control and instant alerts.</div>
            </div>
          </div>
          <div class="mobile-block">
            <img src={nem} alt="cpuc" class="full-image"/>
          </div>
          <div class="mobile-block text">
            <div class="content">
                NEM 3.0
                <div class="subtext">Under NEM 3.0, while compensation for solar energy drops from $0.30 to $0.08 per kWh, pairing solar panels with batteries maximizes benefits. This combination allows homeowners to store and use their solar energy efficiently, reducing grid dependence and enhancing sustainability, thus offsetting the reduced feed-in tariffs.</div>
            </div>
          </div>


        </div>

      </div>
    );
  }
}

export default Battery;