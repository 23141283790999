import React from 'react';
import "./css/home.css";
import solar from "./media/solar.mp4";

const Video = () => {
  const isMobile = window.innerWidth < 768;

  if (isMobile) {
    return null;
  }

  return (
    <video id="video" disablePictureInPicture autoPlay loop muted>
      <source src={solar} type="video/mp4" />
    </video>
  );
};

export default Video;