import React, { useEffect } from 'react';
import './css/refer.css';

const Refer = () => {

  useEffect(() => {
    document.title = "Refer and Earn | PV Solar";
  }, []);

  return (
    <div className="refer-container">
      <div className="refer-content">
        <h1>Buyer Benefits and Referral Credits</h1>
      
        <p>Through PV Solar's referral program, you can earn exclusive benefits by making your first solar system purchase and referring other first-time customers.</p>
        
        <p class="note"><strong>Note:</strong> To earn buyer benefits, you must purchase a complete solar system install with a referral and have never purchased an install from PV Solar in the past. To earn referral credits, you must own a solar system installed by PV Solar.</p>
                        
        <h2>Buyer Benefits</h2>
        
        <p>The offer for buyers is $300 off of your first PV Solar install. To earn buyer benefits with that purchase, you must enter your friend's name in the <a href="/solar-quote">form</a>. Referrals cannot be applied after ordering.</p>
        
        <p>Once you've made your first purchase, you no longer qualify for buyer benefits, but you may still be able to earn referral credits.</p>
        
        <h2>Referral Credits</h2>
        
        <p>You can earn referral credits by having friends or family enter your name in this <a href="/solar-quote">form</a> when they order their first PV Solar install. You will not receive referral credits for referring existing PV Solar customers for purchases.</p>

        <h2>Place an Order</h2>
          <ol>
            <li>Fill out this <a href="/solar-quote">form</a>, check referral, and enter your friend's name</li>
            <li>Have them mention your name when they contact us</li>
            <li>You get $300 off of your installation</li>
            <li>Your friend gets $300 for referring you</li>
          </ol>

        <h2>Refer a Friend</h2>
          <ol>
            <li>Tell your friends about us</li>
            <li>Have them fill out this <a href="/solar-quote">form</a>, check referral, and enter your name</li>
            <li>You get $300 for each friend who installs our solar solution</li>
            <li>Your friend gets $300 off of their installation</li>
          </ol>
            
      </div>      
    </div>
  );
};

export default Refer;