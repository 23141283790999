import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import "./css/quote.css";
import emailjs from 'emailjs-com';
 
export default function GetQuote() {
 const [form, setForm] = useState({
   name: "",
   email: "",
   password: "",
   status: ""
 });
 
 useEffect(() => {
  document.title = "Get your Quote | PV Solar";
}, []);

 const navigate = useNavigate();
 
 // These methods will update the state properties.
 function updateForm(value) {
   return setForm((prev) => {
     return { ...prev, ...value };
   });
 }
 
 function sendEmail(e) {
  e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
  const isReferral = document.getElementsByName('isReferral')[0].checked;

  // Choose the appropriate template ID
  const templateId = isReferral 
    ? process.env.REACT_APP_REFERRAL_TEMPLATE_ID 
    : process.env.REACT_APP_TEMPLATE_ID;

  emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, templateId, e.target, process.env.REACT_APP_USER_ID)
    .then((result) => {
        window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
    }, (error) => {
        console.log(error.text);
    });
}
 
 // This following section will display the form that takes the input from the user.
 return (
  <div class="form">
    <div className="desktop-form-container">
      <div className="side-container">
        <h1>Unlock your savings.</h1>
        <div className="info-text">
        Explore our range of products and services to understand what we offer. 
        Delve into local incentives that can benefit you. 
        Additionally, uncover various financing options tailored to meet your needs.
        </div>
      </div>

      <div class="form-container">
        <form onSubmit={sendEmail}>
          <div className = "form-group-row"> 
            <div className="form-group">
              <label>First Name*</label>
              <input
                type="text"
                className="form-control"
                id="to_first_name"
                name="to_first_name"
                value={form.first_name}
                // onChange={(e) => updateForm({ first_name: e.target.value })}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="name">Last Name*</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="to_last_name"
                value={form.last_name}
                onChange={(e) => updateForm({ last_name: e.target.value })}
                required
              />
            </div>
          </div>
          

          <div className="form-group-row">
            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <input
                type="text"
                className="form-control"
                id="email"
                name="to_email"
                value={form.email}
                onChange={(e) => updateForm({ email: e.target.value })}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone*</label>
              <input
                type="phone"
                className="form-control"
                id="phone"
                name="to_phone"
                value={form.phone}
                onChange={(e) => updateForm({ phone: e.target.value })}
                required
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="address">Address*</label>
            <input
              type="address"
              className="form-control"
              id="address"
              name="to_address"
              value={form.address}
              onChange={(e) => updateForm({ address: e.target.value })}
              required
            />
          </div>

          <div className="form-group checkbox-container">
            <label>
              <input
                type="checkbox"
                name="isReferral"
                checked={form.isReferral}
                onChange={(e) => updateForm({ isReferral: e.target.checked })}
              />
              I was referred by someone
            </label>
          </div>

          {form.isReferral && (
            <div className="form-group">
              <label htmlFor="referrer">Referrer's First and Last Name</label>
              <input
                type="text"
                className="form-control"
                id="referrer"
                name="to_referrer"
                value={form.referrer}
                onChange={(e) => updateForm({ referrer: e.target.value })}
              />
            </div>
          )}

          <div className="form-button">
            <input
              type="submit"
              value="Start your quote"
              className="btn btn-primary"
            />
          </div>
        </form>
      </div>
    </div>
    
    <div className="mobile-form-container">
      <div className="mobile-side-container">
        <h1>Unlock your savings.</h1>
        <div className="info-text">
        Explore our range of products and services to understand what we offer. 
        Delve into local incentives that can benefit you. 
        Additionally, uncover various financing options tailored to meet your needs.
        </div>
      </div>

      <div className="mobile-form-container-inner">
          <form onSubmit={sendEmail}>
              <div className="form-group">
                  <label>First Name*</label>
                  <input
                      type="text"
                      className="form-control"
                      id="from_name"
                      name="to_first_name"
                      value={form.first_name}
                      // onChange={(e) => updateForm({ first_name: e.target.value })}
                      required
                  />
              </div>

              <div className="form-group">
                  <label htmlFor="name">Last Name*</label>
                  <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="to_last_name"
                      value={form.last_name}
                      onChange={(e) => updateForm({ last_name: e.target.value })}
                      required
                  />
              </div>

              <div className="form-group">
                  <label htmlFor="email">Email*</label>
                  <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="to_email"
                      value={form.email}
                      onChange={(e) => updateForm({ email: e.target.value })}
                      required
                  />
              </div>

              <div className="form-group">
                  <label htmlFor="phone">Phone*</label>
                  <input
                      type="phone"
                      className="form-control"
                      id="phone"
                      name="to_phone"
                      value={form.phone}
                      onChange={(e) => updateForm({ phone: e.target.value })}
                      required
                  />
              </div>

              <div className="form-group">
                  <label htmlFor="address">Address*</label>
                  <input
                      type="address"
                      className="form-control"
                      id="address"
                      name="to_address"
                      value={form.address}
                      onChange={(e) => updateForm({ address: e.target.value })}
                      required
                  />
              </div>

              <div className="form-group checkbox-container">
                <label>
                  <input
                    type="checkbox"
                    checked={form.isReferral}
                    onChange={(e) => updateForm({ isReferral: e.target.checked })}
                  />
                  I was referred by someone
                </label>
              </div>

              {form.isReferral && (
                <div className="form-group">
                  <label htmlFor="referrer">Referrer's First and Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="referrer"
                    name="to_referrer"
                    value={form.referrer}
                    onChange={(e) => updateForm({ referrer: e.target.value })}
                  />
                </div>
              )}

              <div className="form-button">
                  <input
                      type="submit"
                      value="Start your quote"
                      className="btn btn-primary"
                  />
              </div>
          </form>
      </div>
    </div>


  </div>
 );
}