import React, { useState, useEffect } from 'react';
import "./css/portfolio.css";
import sun from "./sun.jpg";

function PortfolioItem({ src, alt, city, name, id, description, anotherText }) {
    return (
        <div className="portfolio-item">
            <img src={src} alt={alt} />
            <div className="item-content">
                <p className="city">{city}</p>
                {/* <p className="description">{name}</p>
                <p className="description">{id}</p> */}
                <p className="description"><strong>{description.value}</strong> {description.text}</p>
                <p className="another-text"><strong>{anotherText.value}</strong> {anotherText.text}</p>  {/* Replace with your second line of text */}
            </div>
        </div>

    );
}



export default function Commercial() {
    const [record, setRecord] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [animatedValues, setAnimatedValues] = useState({
        totalPanels: 0,
        totalWatts: 0,
        totalEnergy: 0,
      });

    useEffect(() => {
        document.title = "Commercial Portfolio | PV Solar";
        fetchRecord();
        fetchCustomers();
    }, []);

    function animateValue(key, end, duration) {
        if (isNaN(end)) return;
        console.log(end);
        let start = animatedValues[key];
        let range = end - start;
        let minTimer = 50;
        let stepTime = Math.abs(Math.floor(duration / range));
        stepTime = Math.max(stepTime, minTimer);
        let startTime = new Date().getTime();
        let endTime = startTime + duration;
      
        let timer = setInterval(() => {
          let now = new Date().getTime();
          let remaining = Math.max((endTime - now) / duration, 0);
          let value = Math.round(end - (remaining * range));
          
          setAnimatedValues(prevState => ({ ...prevState, [key]: value }));
      
          if (value === end) {
            clearInterval(timer);
          }
        }, stepTime);
    }

    async function fetchRecord() {
        console.log(process.env.REACT_APP_API_URL);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/record/sum/commercial`);
            
            if (!response.ok) {
                const message = `An error has occurred: ${response.statusText}`;
                console.error(message);
                return;
            }

            const record = await response.json();
            console.log("Record: ");
            console.log(record);
            // this.setState({ record });

            setRecord(record);

            if (record) {
                animateValue("totalPanels", record.totalPanels, 1500);
                animateValue("totalWatts", record.totalWatts, 1500);
                animateValue("totalEnergy", record.totalEnergy, 1500);
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function fetchCustomers() {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/record/customers/commercial`);
            
            if (!response.ok) {
                const message = `An error has occurred: ${response.statusText}`;
                console.error(message);
                return;
            }

            const customers = await response.json();
            console.log("Fetching customers...")
            console.log(customers);
            // this.setState({ customers });
            setCustomers(customers);
        } catch (error) {
            console.error(error);
        }
    }    


    return (
        <div className='portfolio'>
            <div class="portfolio-header">
                <h1 class="title">Commercial Portfolio </h1>
                <p class="subtitle">{animatedValues.totalPanels.toLocaleString()} panels, {animatedValues.totalWatts.toLocaleString()} kW AC, 
                 {animatedValues.totalEnergy.toLocaleString()} MWh generated.</p>
            </div>

            {/* <div className="portfolio">
            {customers.map((customer, index) => (
                <PortfolioItem 
                    key={index}
                    src={customer.image_data || sun} // Use the customer's image if available, otherwise default to 'sun'
                    alt={`Image ${index + 1}`}
                    // city={`${customer.address.city}, ${customer.address.state}`}
                    // name={`${customer.name}`}
                    // id={`${customer._id}`}
                    description={{ value: customer.modules, text: 'Panels'}}
                    anotherText={{ value: customer.size_w, text: 'Watts'}}
                />
            ))}
            </div> */}

        </div>

    );
}

