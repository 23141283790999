import React, { useState, useEffect } from 'react';
import "./css/portfolio.css";
import sun from "./sun.jpg";

function PortfolioItem({ src, alt, city, name, id, description, anotherText }) {
    return (
        <div className="portfolio-item">
            <img src={src} alt={alt} />
            <div className="item-content">
                <p className="city">{city}</p>
                <div className="flex-container">
                    <span className="another-text"><strong>{anotherText.value}</strong> {anotherText.text}</span>
                    <span className="bullet">&bull;</span>
                    <span className="description"><strong>{description.value}</strong> {description.text}</span>
                </div>
            </div>
        </div>
    );
}

export default function Residential() {
    const [record, setRecord] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [animatedValues, setAnimatedValues] = useState({
        totalPanels: 0,
        totalWatts: 0,
        totalEnergy: 0,
        totalWattHours: 0,
        totalKva: 0
      });

    useEffect(() => {
        document.title = "Residential Portfolio | PV Solar";
        fetchRecord();
        fetchNewCustomers();
    }, []);

    function animateValue(key, end, duration) {
        if (isNaN(end)) return;
        console.log(end);
        let start = animatedValues[key];
        let range = end - start;
        let minTimer = 50;
        let stepTime = Math.abs(Math.floor(duration / range));
        stepTime = Math.max(stepTime, minTimer);
        let startTime = new Date().getTime();
        let endTime = startTime + duration;
      
        let timer = setInterval(() => {
          let now = new Date().getTime();
          let remaining = Math.max((endTime - now) / duration, 0);
          let value = Math.round(end - (remaining * range));
          
          setAnimatedValues(prevState => ({ ...prevState, [key]: value }));
      
          if (value === end) {
            clearInterval(timer);
          }
        }, stepTime);
    }

    async function fetchRecord() {
        console.log(process.env.REACT_APP_API_URL);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/record/sum/residential`);
            
            if (!response.ok) {
                const message = `An error has occurred: ${response.statusText}`;
                console.error(message);
                return;
            }

            const record = await response.json();
            console.log("Record: ");
            console.log(record);
            // this.setState({ record });

            setRecord(record);

            if (record) {
                animateValue("totalPanels", record.totalPanels, 1500);
                animateValue("totalWatts", record.totalWatts, 1500);
                animateValue("totalEnergy", record.totalEnergy, 1500);
                animateValue("totalWattHours", record.totalWattHours, 1500);
                animateValue("totalKva", record.totalKva, 1500);
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function fetchNewCustomers() {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/record/special-customers/residential`);
            
            if (!response.ok) {
                const message = `An error has occurred: ${response.statusText}`;
                console.error(message);
                return;
            }
    
            let customers = await response.json();
            console.log(customers);
    
            // Update customers to use the image URL from S3
            customers = customers.map(customer => {
                if (customer.image) {
                    customer.imageUrl = customer.image; 
                } else {
                    customer.imageUrl = sun;
                }
                return customer;
            });
    
            setCustomers(customers);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className='portfolio'>
            <div class="portfolio-header">
                <h1 class="title">Residential Portfolio </h1>
                <p class="subtitle">{animatedValues.totalPanels.toLocaleString()} panels | {animatedValues.totalWatts.toLocaleString()} kW AC 
                | {animatedValues.totalKva.toLocaleString()} kVA of battery power 
                | {animatedValues.totalWattHours.toLocaleString()} kWh of energy storage 
                | {animatedValues.totalEnergy.toLocaleString()} MWh generated.</p>
            </div>

            <div className="portfolio">
            {customers.map((customer, index) => (
                <PortfolioItem 
                    key={index}
                    src={customer.imageUrl}
                    alt={`Image ${index + 1}`}
                    city={`${customer.address.city}, ${customer.address.state}`}
                    // name={`${customer.name}`}
                    // id={`${customer._id}`}
                    description={{ value: customer.modules, text: 'Panels'}}
                    anotherText={{ value: (customer.size_w) / 1000, text: 'kW'}}
                />
            ))}
            </div>
        </div>
    );
}