import React from 'react';
import "./css/about.css";
import sun from "./sun.jpg";
import location from "./media/location.PNG";
import solar from "./media/solar-farm.jpg";
import storage from "./media/storage.PNG";
import nem from "./media/cpuc.jpg"


class About extends React.Component {

  componentDidMount() {
    document.title = "About Us | PV Solar";
  }
  
  render() {
    return (
      <div>
        <div class="about-container">
          <div class="block about">
              About
          </div>
          <div class="block split">
            <div class="half text">
              <div class="content">
                  Location
                  <div class="subtext">We proudly serve the entire San Diego County, including Chula Vista, Oceanside, Escondido, Carlsbad, Jamul, Bonita, Del Mar, La Jolla, and more.</div>
              </div>
            </div>
            <div class="half">
              <img src={location} alt="map" class="full-image"/>
            </div>
          </div>
          <div class="block split">
              <div class="half ">
                <img src={solar} alt="Solar Farm" class="full-image"/>
              </div>
              <div class="half text">
                <div class="content">
                    Solar Panels
                    <div class="subtext">Transform your home with our advanced, sustainable solar technology, reducing energy costs and enhancing home value through eco-friendly, long-term savings. Embrace renewable energy for efficient, self-sufficient power, leading the way in energy innovation and eco-conscious living.</div>
                </div>
              </div>
          </div>
          <div class="block split">
              <div class="half text">
                <div class="content">
                    Batteries
                    <div class="subtext">Enhance energy independence and savings with our advanced battery systems, designed for outage protection and grid support, complete with convenient remote control and instant alerts.</div>
                </div>
              </div>
              <div class="half">
                <img src={storage} alt="Enphase Energy Storage" class="full-image"/>
              </div>
          </div>
          <div class="block split">
              <div class="half ">
                <img src={nem} alt="California Public Utilities Commission" class="full-image"/>
              </div>
              <div class="half text">
                <div class="content">
                    NEM 3.0
                    <div class="subtext">Under NEM 3.0, while compensation for solar energy drops from $0.30 to $0.08 per kWh, pairing solar panels with batteries maximizes benefits. This combination allows homeowners to store and use their solar energy efficiently, reducing grid dependence and enhancing sustainability, thus offsetting the reduced feed-in tariffs.</div>
                </div>
              </div>
          </div>
        </div>

        <div className="mobile-about-container">
          <div class="mobile-block about">
              About
          </div>
          <div class="mobile-block">
            <img src={location} alt="map" class="full-image"/>
          </div>
          <div class="mobile-block text">
            <div class="content">
                Location
                <div class="subtext">We proudly serve the entire San Diego County, including Chula Vista, Oceanside, Escondido, Carlsbad, Jamul, Bonita, Del Mar, La Jolla, and more.</div>
            </div>
          </div>
          <div class="mobile-block">
            <img src={solar} alt="solar" class="full-image"/>
          </div>
          <div class="mobile-block text">
              <div class="content">
                  Solar Panels
                  <div class="subtext">Transform your home with our advanced, sustainable solar technology, reducing energy costs and enhancing home value through eco-friendly, long-term savings. Embrace renewable energy for efficient, self-sufficient power, leading the way in energy innovation and eco-conscious living.</div>
              </div>
          </div>
          <div class="mobile-block">
            <img src={storage} alt="storage" class="full-image"/>
          </div>
          <div class="mobile-block text">
            <div class="content">
                Batteries
                <div class="subtext">Enhance energy independence and savings with our advanced battery systems, designed for outage protection and grid support, complete with convenient remote control and instant alerts.</div>
            </div>
          </div>
          <div class="mobile-block">
            <img src={nem} alt="cpuc" class="full-image"/>
          </div>
          <div class="mobile-block text">
            <div class="content">
                NEM 3.0
                <div class="subtext">Under NEM 3.0, while compensation for solar energy drops from $0.30 to $0.08 per kWh, pairing solar panels with batteries maximizes benefits. This combination allows homeowners to store and use their solar energy efficiently, reducing grid dependence and enhancing sustainability, thus offsetting the reduced feed-in tariffs.</div>
            </div>
          </div>


        </div>

      </div>
    );
  }
}

export default About;
