import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';
// We import all the components we need in our app
import Navbar from "./components/navbar";
import GetQuote from "./components/getQuote";
import Home from "./components/home";
import Residential from "./components/residential";
import About from "./components/about";
import Contact from "./components/contact";
import Footer from "./components/footer";
import Commercial from "./components/commercial";
import Battery from "./components/battery";
import Maintenance from "./components/maintenance";
import Refer from "./components/refer"

 
const App = () => {

  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("G-WHCSWLK15B");
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    <div>
      <Navbar/>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route path="/solar-quote" element={<GetQuote/>} />
          <Route path="/residential" element={<Residential/>} />
          <Route path="/commercial" element={<Commercial/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/contact" element={<Contact/>} />
          {/* <Route path="/battery-storage" element={<Battery/>} /> */}
          <Route path="/maintenance" element={<Maintenance/>} />
          <Route path="/refer-and-earn" element={<Refer/>} />
        </Routes>
        <Footer/>
    </div>
  );
};
 
export default App;